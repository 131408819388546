<template>
  <div class="mapBody  flex-row h-100vh w-100">
    <div class="list bgc-w w-20rem p-10 b-shadow bdr-4 animate__animated animate__backInRight">
      <div>地点列表</div>
      <ul class="mt-10">
        <li class="mt-10 bdw-1 bdc-bs bdr-4" v-for="item1 in maplist" :key="item1.id">
          <div class=" flex-row">
            <div class="swiper w-70 mr-10">
              <el-carousel height="5rem" :autoplay="false">
                <el-carousel-item v-for="(item, index) in item1.imgArr.split(',')" :key="index">
                  <el-image class="w-100 h-100" :src="item" fit="cover"></el-image>
                </el-carousel-item>
              </el-carousel>
            </div>
            <div class="flex-col w-100">
              <div class=" text-left font-16 ph-5 curp" @click="goPoint(item1)">{{ item1.name }}</div>
              <div class="intro w-10rem  text-left font-12 color-gray  t-hd-two mh-2rem">
                {{ item1.intro }}</div>
              <div class="flex-row flex-jsb w-50 flex-ac ">
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="w-100 mt-10">
        <el-pagination small background layout="prev, pager, next" :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" :current-page="queryInfo.pageNo" :page-size="queryInfo.pageSize">
        </el-pagination>
      </div>
    </div>
    <div id="index" class="h-80vh w-100 bgc-w">
      <baidu-map class="map w-100 h-100" :center="center" :zoom="zoom" :dragging="true" :scroll-wheel-zoom="true"
        @ready="handler">
        <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-map-type>
        <!--:icon="{ url: 'https://culture-1303829981.cos.ap-shanghai.myqcloud.com/culture/common/house.png', size: { width: 200, height: 150, } }" -->
        <bm-marker :position="{ lng: location.log + '', lat: location.lat + '' }" style="object-fit: cover;"
          :dragging="false" @click="infoWindowOpen">
          <!-- :labelStyle="{ color: '#452a0e', fontSize: '12px', border: '1px solid #452a0e', padding: '2px 5px', borderRadius: '3px' }" -->
          <bm-label :content="location.name" @click="infoWindowOpen" :offset="{ width: 0, height: 30 }"
            :labelStyle="labelStyle" />
          <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">
            <div style="width: 400px;" v-if="location.imgArr">
              <p style="font-size: 14px;color: #373737;letter-spacing: 1px;text-indent: 2em;"> {{ location.intro }}</p>
            </div>
          </bm-info-window>
        </bm-marker>
        <div class=" zoomBtn absolute t-20 l-20">
          <el-button icon="el-icon-plus" circle @click="zoom++" type="primary" :disable="zoom > 5"></el-button>
          <div></div>
          <el-button icon="el-icon-minus" circle @click="zoom--" type="primary" :disable="zoom < 1"></el-button>
        </div>
      </baidu-map>
    </div>
  </div>
</template>

<script>
import mapApi from "@/api/request/map";
import commmonApi from "@/api/request/common";
import * as map from "vue-baidu-map";
console.log(map)
export default {
  name: "greatMap",
  data() {
    return {
      
      labelStyle: { color: '#452a0e', fontSize: '12px', border: '1px solid #452a0e', padding: '2px 5px', borderRadius: '3px' },
      base_url: process.env.VUE_APP_IMG_BASE_URL,
      center: { lng: 0, lat: 0 },
      location: {},
      maplist: [],
      zoom: 0,
      show: false,
      queryInfo: {
        pageSize: 4, pageNo: 1
      },
      total: 0,
    };
  },
  created() {
    this.getList();
  },
  methods: {

    infoWindowClose() {
      this.show = false
    },
    infoWindowOpen() {
      this.show = true
    },
    // 用户点击喜欢
    like(e) {
      commmonApi.likeRecord({
        partName: '特色地图',
        partId: e.id,
        isLike: '1',
      }).then(res => {
        if (res.success) {
          mapApi.updateMap({
            likeNum: e.likeNum + 1,
            id: e.id
          }).then(res2 => {
            if (res2.success) {
              e.likeNum = e.likeNum + 1;
              this.$message.success("点赞成功")
            }
          })
        } else {
          this.$message(res.msg)
        }
      })
    },
    // 点击框，去该地点
    goPoint(e) {
      this.location = e;
      this.center.lng = e.log
      this.center.lat = e.lat;
      this.infoWindowOpen();
    },
    // 获取地图列表
    getList() {
      mapApi.mapList({
        ...this.queryInfo
      }).then(res => {
        if (res.success) {
          this.maplist = res.data.list
          this.total = res.data.searchParams.total;
        }
      })
    },
    handleCurrentChange(e) {
      this.queryInfo.pageNo = e;
      this.getList();

    },
    handleSizeChange(e) {
      this.queryInfo.pageSize = e;
      this.getList();
    },
    handler({ BMap, map }) {
      this.center.lng = 114.881514;
      this.center.lat = 33.473007;
      this.zoom = 15;
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../theme/myStyle.less"; //全局样式

.mapBody {
  // flex: 1;
  position: relative;
  height: 100vh;

  #index {
    z-index: 2;
  }

  .list {
    position: absolute;
    right: 50px;
    margin-top: 50px;
    z-index: 200;

    .swiper {
      height: 5rem;
      // background-color: #00000090;
      // width: 9rem;
    }
  }
}

.zoomBtn {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50px;
  right: 5px;
  width: 40px;
  height: 90px;
  justify-content: space-between;
}

.crup {
  cursor: pointer;
}

.anchorBL {
  display: none !important;
}

/deep/.el-carousel__indicator,
/deep/.el-carousel__button {
  width: 2px !important;
}

/deep/ .el-carousel__arrow--right {
  right: 5px;
}

/deep/ .el-carousel__arrow--left {
  left: 5px;
}

.intro {
  word-break: break-all;
  -webkit-line-clamp: 3;
}
</style>